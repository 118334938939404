import { logAppClosed } from './analytics'

const closeApp = () => {
  logAppClosed()
  window.epharmacy_close?.()
  window.parent.postMessage(
    {
      type: 'closing'
    },
    '*'
  )
}

export default closeApp
