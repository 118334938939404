import type React from 'react'
import { ButtonHTMLAttributes, type JSXElementConstructor, type ReactNode } from 'react'
import Link from 'next/link'
import cn from 'clsx'
import CircularProgress from './CircularProgress'

interface Props extends React.ComponentProps<'button'> {
  Component?: React.ElementType | JSXElementConstructor<any>
  variant?: 'text' | 'contained' | 'outlined'
  href?: string
  color?: 'primary' | 'secondary' | 'success'
  fullWidth?: boolean
  loading?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
}

const Button: FC<Props> = ({
  href,
  Component = href ? Link : 'button',
  variant = 'contained',
  color = 'primary',
  fullWidth = variant !== 'text',
  children,
  className,
  startIcon,
  endIcon,
  disabled,
  loading,
  ...rest
}) => {
  const classname = cn(
    'flex gap-2 items-center text-sm uppercase justify-center rounded-md cursor-pointer font-extrabold transition-all no-underline',
    {
      'w-full': fullWidth,
      // Colors
      'text-primary': ['text', 'outlined'].includes(variant) && color === 'primary',
      'text-secondary': ['text', 'outlined'].includes(variant) && color === 'secondary',
      'text-success': ['text', 'outlined'].includes(variant) && color === 'success',
      // VARIANTS
      'bg-primary border border-primary text-white hover:bg-primary-900 shadow py-5 px-6': variant === 'contained',
      'hover:bg-primary-100  py-3.5 px-6': variant === 'text',
      'border border-solid py-5 px-6 hover:bg-gray-200': variant === 'outlined',
      // MIX
      'border-primary': variant === 'outlined' && color === 'primary',
      'border-success': variant === 'outlined' && color === 'success',

      '!bg-primary-100 cursor-not-allowed pointer-events-none !text-primary-300 !border-gray-100': disabled
    },
    className
  )

  return (
    <Component className={classname} href={href} disabled={disabled} {...rest}>
      {startIcon}
      {loading ? <CircularProgress size={20} /> : children}
      {endIcon}
    </Component>
  )
}

export default Button
